<style lang="less">
@import "./EditUnit.less";
</style>
<template>
  <Row>
    <Col :xs="{ span: 22, offset: 1 }" :lg="{ span: 12, offset: 6 }">
      <!-- <Button @click="handleBack()">返回</Button> -->
      <Form
        :model="formItem"
        ref="editUnitForm"
        :rules="ruleValidate"
        label-position="right"
        @submit.native.prevent
        :label-width="100"
      >
        <FormItem label="课程代码:" style="width: 350px">
          <Input
            v-model="formItem.course_code"
            disabled
            placeholder="请输入课程代码"
          ></Input>
        </FormItem>
        <FormItem label="课程名称:" style="width: 350px">
          <Input
            v-model="formItem.course_name"
            disabled
            placeholder="请输入课程名称"
          ></Input>
        </FormItem>
        <FormItem label="学校:" style="width: 350px">
          <Select v-model="formItem.campus" disabled placeholder="请选择学校">
            <Option :value="formItem.campus">{{ formItem.campus }}</Option>
          </Select>
        </FormItem>
        <FormItem label="学院:" style="width: 350px">
          <Select v-model="formItem.faculty" disabled placeholder="请选择学院">
            <Option :value="formItem.faculty" :key="formItem.faculty">
              {{ formItem.faculty }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="学期:" style="width: 350px">
          <Select
            v-model="formItem.semester"
            placeholder="请选择学期"
            class="select-semester"
          >
            <Option
              v-for="(is_active, sem) in semesters"
              :value="sem"
              :key="sem"
              :label="sem"
              :class="{ disable: !is_active }"
            >
              {{ sem }}
              <span v-if="!is_active"> (未激活) </span>
            </Option>
          </Select>
        </FormItem>
        <FormItem label="任课老师:" style="width: 350px">
          <Select
            v-if="privilege >= 2"
            v-model="formItem.teacher"
            placeholder="请选择任课老师"
          >
            <Option
              v-for="item in teacherList"
              :value="item.username"
              :key="item.value"
              :label="item.value"
              ><span>{{ item.value }}</span>
              <span style="float: right; color: #ccc">{{
                item.username
              }}</span></Option
            >
          </Select>
          <Select
            v-else
            disabled
            v-model="formItem.teacher"
            placeholder="请选择任课老师"
          >
            <Option
              v-for="item in teacherList"
              :value="item.username"
              :key="item.value"
              :label="item.value"
              ><span>{{ item.value }}</span>
              <span style="float: right; color: #ccc">{{
                item.username
              }}</span></Option
            >
          </Select>
        </FormItem>
        <FormItem label="学生数量:" style="width: 350px">
          <Input v-model="formItem.studentCount" disabled></Input>
        </FormItem>
        <FormItem label="课程价格:" prop="price" style="width: 350px">
          <Input
            v-if="privilege >= 2"
            v-model="formItem.price"
            placeholder="Enter price"
            prefix="logo-usd"
          ></Input>
          <Input
            v-else
            disabled
            v-model="formItem.price"
            placeholder="Enter price"
            prefix="logo-usd"
          ></Input>
        </FormItem>
        <FormItem label="课程描述:" prop="description" style="width: 500px">
          <Input
            v-model="formItem.description"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 10 }"
            maxlength="1000"
            show-word-limit
            placeholder="课程描述"
          />
        </FormItem>
        <div>
          <Button
            type="primary"
            style="margin-left: 0px"
            @click="handleUpdate('editUnitForm')"
            >更新</Button
          >
          <Button
            style="margin-left: 15%"
            v-if="privilege >= 2"
            type="error"
            @click="showConfirm()"
            >删除</Button
          >
          <Button style="margin-left: 15%" @click="handleBack()">取消</Button>
        </div>
      </Form>
    </Col>
  </Row>
</template>
<script>
export default {
  data() {
    const validatePrice = (rule, value, callback) => {
      if (value === "" || isNaN(value)) {
        callback(new Error("Please enter price"));
      } else {
        if (value < 10 || value > 10000) {
          callback(new Error("Price should be between 10 to 10000"));
        }
        callback();
      }
    };
    return {
      formItem: {
        campus: "",
        faculty: "",
        course_code: "",
        course_name: "",
        teacher: "",
        studentCount: 0,
        price: 0.0,
        description: ""
      },
      semesters: {},
      teacherList: [],
      ruleValidate: {
        price: [
          {
            required: true,
            message: "The price cannot be empty",
            trigger: "blur"
          },
          // { type: 'number', min: 10, max: 10000, message: 'The price should be a number', trigger: 'blur' },
          { validator: validatePrice, trigger: "blur" }
        ],
        description: [
          {
            required: true,
            message: "The description cannot be empty",
            trigger: "blur"
          },
          {
            type: "string",
            min: 1,
            message: "The description length should less than 200 words",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    handleBack() {
      this.$router.push("/manage-units");
    },
    handleUpdate(name) {
      // console.log(this.$refs['newUnitForm']);
      this.$refs[name].validate(valid => {
        if (valid) {
          const price = this.formItem.price * 1;
          const teacher = this.formItem.teacher;
          const description = this.formItem.description;
          const semester = this.formItem.semester;
          const updateInfo = { price, teacher, description, semester };

          this.axios
            .put(`api/course/${this.formItem.course_code}`, updateInfo)
            .then(resp => {
              // console.log(resp.data);
              if (resp.data.course_id) {
                this.$Message.success(`更新成功`);
                this.$router.push("/manage-units");
              }
            })
            .catch(() => {
              this.$Message.error(`更新失败`);
            });
          // this.$router.push("/allUnit");
        } else {
          this.$Message.error("Failed to update the unit!");
        }
      });
    },
    handleDelete() {
      this.axios
        .delete(`api/course/${this.formItem.course_code}`)
        .then(resp => {
          if (resp.status === 200) {
            this.$Message.success(`删除成功`);
            this.$router.push("/manage-units");
          }
        })
        .catch(() => {
          this.$Message.error(`删除失败`);
        });
    },
    showConfirm() {
      this.$Modal.confirm({
        title: `确认删除`,
        loading: true,
        onCancel: () => {
          this.$Modal.remove();
        },
        cancelText: "取消",
        onOk: async () => {
          try {
            this.handleDelete();
          } catch {
            this.$Message.error("删除失败");
          } finally {
            this.$Modal.remove();
          }

          // const { course_code, campus, faculty } = row;
          //   const selectedUnitInfo = JSON.stringify({
          //     course_code,
          //     campus,
          //     faculty
          //   });
          //   try {
          //     const resp = await this.axios.post(
          //       `api/courses/signed`,
          //       selectedUnitInfo
          //     );
          //     this.$Message.success(
          //       `恭喜${resp.data.student}!您已经成功注册了${resp.data.course_code}。`
          //     );
          //     this.$router.push("/myorder");
          //   } catch {
          //     this.$Message.error("注册课程失败");
          //   } finally {
          //     this.$Modal.remove();
          //   }
        }
      });
    }
  },

  computed: {
    privilege: function () {
      return this.$store.getters.getCurrentUser.privilege;
    }
    // username: function() {
    //   return this.$store.getters.getCurrentUser.username
    // }
  },
  mounted: function () {
    const id = this.$route.query.id;
    this.axios
      .get(`api/course/${id}`)
      .then(resp => {
        // console.log(resp.data);
        const courseInfo = resp.data;
        this.formItem.campus = courseInfo.campus;
        this.formItem.faculty = courseInfo.faculty;
        this.formItem.semester = courseInfo.semester;
        this.formItem.course_code = courseInfo.course_code;
        this.formItem.course_name = courseInfo.course_name;
        this.formItem.teacher = courseInfo.teacher;
        this.formItem.price = courseInfo.price.toFixed(2);
        this.formItem.description = courseInfo.description;
        return courseInfo.course_code;
      })
      .then(code => {
        this.axios.get(`api/course/${code}/students`).then(resp1 => {
          const studentCount = resp1.data.length;
          // console.log(studentCount);
          this.formItem.studentCount = studentCount;
          this.axios
            .get(`api/campus/${this.formItem.campus}/semesters`)
            .then(resp => {
              this.semesters = resp.data;
            });
        });
      });
    this.axios.get("api/teachers").then(resp => {
      const teachersData = resp.data;
      this.teacherList = teachersData.map(teacher => ({
        value: teacher.display_name,
        username: teacher.username
      }));
    });

    this.$Message.config({
      top: 50,
      duration: 3
    });
  }
};
</script>
